import { getListInvoiceDetailAgency, getPdfInvoiceAgency } from "api/invoice";
import { ArrowLeft } from "assets/icon";
import PaginationCustom from "components/PaginationCustom";
import dayjs from "dayjs";
import useFilter from "hooks/useFilter";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import Loading from "pages/Loading/Loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_LIMIT } from "utils/const";
import { QueryKey } from "utils/enum";
import {
  convertOriginFilter,
  convertPoint,
  handleErrorMessage,
} from "utils/helper";
import TableDetailInvoice from "./Component/TableDetailInvoice";
import styles from "./styles.module.scss";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export default function InvoiceAgencyDetail() {
  const [t] = useTranslation();
  const queryParams = queryString.parse(window.location.search) as any;
  const location = useLocation();
  const { startAt, endAt } = location.state || {};

  const [currentDate, setCurrentDate] = useState(
    !!startAt ? dayjs(startAt) : dayjs()
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const { filter, handleSearch, handleFilterChange, handlePageChange } =
    useFilter(
      useGetFilterQuery({
        defaultFilter: {
          page: 1,
          limit: DEFAULT_LIMIT,
          startAt: !!startAt ? startAt : dayjs().startOf("month").toISOString(),
          endAt: !!endAt ? endAt : dayjs().endOf("month").toISOString(),
        },
        convertFilterToValidForm: convertOriginFilter,
      }),
      true
    );

  const { data: listInvoiceDetail } = useQuery(
    [QueryKey.LIST_INVOICE_DETAIL_AGENCY, filter],
    () =>
      getListInvoiceDetailAgency(Number(id), {
        ...filter,
      }),
    {
      onSuccess: (data) => {
        // console.log({ data });
      },
    }
  );

  const handleBack = () => {
    handleFilterChange({
      startAt: dayjs(currentDate.subtract(1, "month"))
        .startOf("month")
        .toISOString(),
      endAt: dayjs(currentDate.subtract(1, "month"))
        .endOf("month")
        .toISOString(),
    });
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  // Hàm để đi tới tháng tiếp theo
  const handleNext = () => {
    handleFilterChange({
      startAt: dayjs(currentDate.add(1, "month"))
        .startOf("month")
        .toISOString(),
      endAt: dayjs(currentDate.add(1, "month")).endOf("month").toISOString(),
    });
    setCurrentDate(currentDate.add(1, "month"));
  };

  const { mutate: downloadPdfInvoice, isLoading: loadingDownload } =
    useMutation(
      () =>
        getPdfInvoiceAgency(Number(id), {
          startAt: currentDate.startOf("month").toISOString(),
          endAt: currentDate.endOf("month").toISOString(),
        }),
      {
        onSuccess: (data) => {
          window.open(data?.data);
        },
        onError: (err: any) => {
          handleErrorMessage(err);
        },
      }
    );

  return (
    <div className={styles.container}>
      {loadingDownload && <Loading />}

      <div className={styles.header}>
        <div className={styles.back}>
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>{t("invoice.detail")}</span>
        </div>
      </div>
      <div className={styles.titleMiddle}>
        <p className={styles.wrapNameAgencyTitle}>
          {listInvoiceDetail?.data?.staffInCharge?.name}
        </p>
        <div className={styles.wrapRow}>
          <div className={styles.wrapAmountContainer}>
            <p className={styles.wrapAmount}>
              {t("invoice.bill")}
              <span className={styles.amount}>{` ¥${convertPoint(
                listInvoiceDetail?.data?.totalAmountFee
              )}`}</span>
              <span className={styles.tax}>{t("invoice.notIncludeTax")}</span>
            </p>
          </div>
          <div className={styles.wrapRowFilter}>
            {dayjs().diff(dayjs(currentDate), "month") <= 5 && (
              <div onClick={handleBack} className={styles.wrapIconBack} />
            )}

            <p className={styles.titleAmount}>
              {currentDate.format("YYYY年M月")}
            </p>
            {!dayjs().isSame(dayjs(currentDate), "month") && (
              <div onClick={handleNext} className={styles.wrapIconNext} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.wrapTable}>
        <TableDetailInvoice
          data={listInvoiceDetail?.data?.items || []}
          filter={filter}
          loading={false}
        />
      </div>

      <PaginationCustom
        pageIndex={filter.page || 1}
        pageSize={filter.limit || 10}
        onPageChange={handlePageChange}
        totalItems={listInvoiceDetail?.data?.totalItem || 0}
        className="mt-20"
      />
      <div
        className={styles.wrapButtonDownLoad}
        onClick={() => {
          downloadPdfInvoice();
        }}
      >
        {t("invoice.downloadPdf")}
      </div>
    </div>
  );
}
