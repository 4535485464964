import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

// import TableStore from "./components/TableStore";
import { getListHistory } from "api/post";
import socket from "api/socket";
import PaginationCustom from "components/PaginationCustom";
import useFilter from "hooks/useFilter";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import { useEffect, useState } from "react";
import { DEFAULT_LIMIT, statusFilterUser } from "utils/const";
import { QueryKey } from "utils/enum";
import HistoryFilter from "./components/HistoryFilter";
import HistoryTable from "./components/HistoryTable";
import styles from "./styles.module.scss";

export default function ApplicationHistory() {
  const [t] = useTranslation();

  const { filter, handleSearch, handleFilterChange, handlePageChange } =
    useFilter(
      useGetFilterQuery({
        defaultFilter: {
          page: 1,
          limit: DEFAULT_LIMIT,
        },
      }),
      true
    );

  const { data: listUserManage, isLoading: loadingListListPost } = useQuery(
    [QueryKey.LIST_USER, filter],
    () =>
      getListHistory({
        ...filter,
      }),
    {
      onSuccess: (data) => {},
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.userHeader}>
        <div className={styles.topHeader}>
          <div className={styles.title}>{t("application.header")}</div>
        </div>
        <HistoryFilter
          filter={filter}
          handleSearch={handleSearch}
          dataSelect={statusFilterUser}
          onSearch={handleFilterChange}
        />
      </div>
      <div className={styles.storeTable}>
        <HistoryTable
          data={listUserManage?.data?.items || []}
          filter={filter}
          loading={false}
        />
        <PaginationCustom
          pageIndex={filter.page || 1}
          pageSize={filter.limit || 10}
          onPageChange={handlePageChange}
          totalItems={listUserManage?.data?.totalItems || 0}
          className="mt-20"
        />
      </div>
    </div>
  );
}
