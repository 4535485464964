import { Checkbox, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { updateStatusInvoice } from "api/invoice";
import { Detail } from "components/ActionTable/ActionTable";
import { usePermissions, useRole } from "hooks/usePermissions";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKey } from "utils/enum";
import { convertPoint, getIndexTable, handleErrorMessage } from "utils/helper";
import { IApplicationRow, IFilter } from "utils/interface";
import styles from "./styles.module.scss";

interface IProps {
  data: IApplicationRow[];
  filter: IFilter;
  loading: boolean;
  listCheckDownloadPdf: number[];
  setListCheckDownloadPdf(data: number[]): void;
  setIsCheckAll(data: any): void;
  isCheckAll: any;
  listCheckException: number[];
  setListCheckException(data: number[]): void;
  startAt?: string;
  endAt?: string;
}

export default function InvoiceTable({
  data,
  filter,
  loading,
  listCheckDownloadPdf,
  setListCheckDownloadPdf,
  setIsCheckAll,
  isCheckAll,
  listCheckException,
  setListCheckException,
  startAt,
  endAt,
}: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const permissions = usePermissions();
  const role = useRole();
  const queryClient = useQueryClient();

  const { mutate: changeStatus, isLoading: loadingUpdateStatus } = useMutation(
    updateStatusInvoice,
    {
      onSuccess: () => {
        // CustomNotification({
        //   type: "success",
        //   message: t("notification.success"),
        // });
        // onRefetch?.();
        queryClient.invalidateQueries([QueryKey.LIST_INVOICE]);
      },
      onError: (err: any) => {
        handleErrorMessage(err);
      },
    }
  );

  const columns: ColumnsType<any> = [
    {
      title: (
        <div className={styles.wrapItemTable}>
          <Checkbox
            onChange={(value) => {
              if (value?.target?.checked) {
                setIsCheckAll(true);
                setListCheckDownloadPdf([]);
                setListCheckException([]);
              } else {
                setIsCheckAll(false);
                setListCheckDownloadPdf([]);
                setListCheckException([]);
              }
            }}
            checked={isCheckAll}
          />
        </div>
      ),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div className={styles.wrapItemTable}>
          <Checkbox
            onChange={() => {
              if (isCheckAll) {
                setIsCheckAll(false);
                setListCheckException([record?.id]);
                setListCheckDownloadPdf([]);
              } else {
                if (listCheckException?.length > 0) {
                  if (listCheckException?.includes(record?.id)) {
                    const listTemp = listCheckException?.filter(
                      (item: number) => item !== record?.id
                    );
                    setListCheckException(listTemp);
                  } else {
                    setListCheckException([...listCheckException, record?.id]);
                  }
                } else {
                  if (listCheckDownloadPdf?.includes(record?.id)) {
                    const listTemp = listCheckDownloadPdf?.filter(
                      (item: number) => item !== record?.id
                    );
                    setListCheckDownloadPdf(listTemp);
                  } else {
                    setListCheckDownloadPdf([
                      ...listCheckDownloadPdf,
                      record?.id,
                    ]);
                  }
                }
              }
            }}
            checked={
              isCheckAll
                ? isCheckAll
                : listCheckException?.length > 0
                ? !listCheckException?.includes(record?.id)
                : listCheckDownloadPdf?.includes(record?.id)
            }
          />
        </div>
      ),
    },
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
    },
    {
      title: t("table.nameStore"),
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (value, record, index) => <div>{record?.name}</div>,
    },
    {
      title: (
        <div className={styles.wrapItemTable}>
          <p>{t("table.status")}</p>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: "20%",

      render: (value, record, index) => (
        <>
          {!!record?.invoice?.id && (
            <div className={styles.wrapRow}>
              <div
                className={styles.wrapItemInvoice}
                style={{
                  backgroundColor: record?.invoice?.invoiceStatus
                    ? "#87d6ea"
                    : "white",
                }}
                onClick={() => {
                  changeStatus({
                    invoiceId: record?.invoice?.id,
                    invoiceStatus: !record?.invoice?.invoiceStatus,
                  });
                }}
              >
                {record?.invoice?.invoiceStatus
                  ? t("invoice.invoiced")
                  : t("invoice.invoice")}
              </div>
              <div
                className={styles.wrapItemInvoice}
                style={{
                  backgroundColor: record?.invoice?.paymentStatus
                    ? "#87d6ea"
                    : "white",
                }}
                onClick={() => {
                  changeStatus({
                    invoiceId: record?.invoice?.id,
                    paymentStatus: !record?.invoice?.paymentStatus,
                  });
                }}
              >
                {record?.invoice?.paymentStatus
                  ? t("invoice.paid")
                  : t("invoice.payment")}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: <p>{t("table.agencyName")}</p>,
      dataIndex: "agencyName",
      key: "agencyName",
      width: "20%",
      render: (value, record, index) => (
        <div className={styles.wrapStatus}>{record?.agency?.name}</div>
      ),
    },
    {
      title: <p className={styles.priceCol}>{t("table.price")}</p>,
      dataIndex: "price",
      key: "price",
      render: (value, record, index) => (
        <div className={styles.priceCol}>
          {!record?.invoice ? "_" : convertPoint(record?.invoice?.totalAmount)}
        </div>
      ),
    },
    {
      title: t("table.action"),
      key: "action",
      render: (value, record, index) => (
        <div className="flex justify-start align-center">
          <Detail
            onClick={() =>
              navigate(`/invoice-list/detail/${record?.id}`, {
                state: { startAt, endAt },
              })
            }
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
      />
    </>
  );
}
