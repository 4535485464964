import { sendDelete, sendGet, sendPatch, sendPost } from "./axios";

interface IPramAddPoint {
  businessId: number;
  amount: number;
}

export const getPostList = (params: any) =>
  sendGet("/operation/recruitment-post", params);

export const getDetailPostApi = (id?: string | number) =>
  sendGet(`/operation/recruitment-post/${id}`);

export const deletePostApi = (id?: string | number) =>
  sendDelete(`/operation/recruitment-post/${id}`);

export const updatePostApi = (id: string | number, params: any) =>
  sendPatch(`/operation/recruitment-post/${id}`, params);

export const getTreatmentList = () => sendGet("/operation/treatment/dropdown");

export const updatePostAutoApi = (id: string | number, params: any) =>
  sendPatch(`/operation/recruitment-post/automatic-schedule/${id}`, params);

export const getListHistory = (params: any) =>
  sendGet("/operation/recruitment-post/recruitment-post-accounts", params);

export const addPointApi = (params: IPramAddPoint) =>
  sendPost(`/operation/invoice/credit-point`, params);
